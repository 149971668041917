import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';

import { useSecurity } from '../../auth';
import {
  MainMenu,
  MainMenuOption,
  MainMenuSelectOption,
} from '../../components/MainMenu';
import { gtag } from '../../gtag';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';
import { withTraslation } from '../../data/LanguageProvider';

const convertOldSettings = pm => {
  const oldSetting = pm.get('currency');
  pm.set('useUsdAmount', oldSetting === 'USD');
  pm.delete('currency');
};

const getInitialState = pm => {
  convertOldSettings(pm);
  let v1 = pm.get('useUsdAmount');
  let v2 = pm.get('includeTaxes');
  let v3 = pm.get('useLLY');
  let v4 = pm.get('preferredLanguage');

  // Since we are disintegrating zn-TW (Chinese T) from the UI
  if (v4 === 'zh-TW') {
    v4 = 'en';
    pm.set('preferredLanguage', v4);
  }

  return {
    useUsdAmount: v1 !== undefined ? Boolean(v1) : false,
    includeTaxes: v2 !== undefined ? Boolean(v2) : false,
    useLLY: v3 !== undefined ? Boolean(v3) : false,
    preferredLanguage: v4 !== undefined ? v4 : 'en',
  };
};

const UserShell = ({ children, onClose = noop, setLanguage, history }) => {
  const pm = usePreferencesManager();
  const [settings, setSettings] = useState(getInitialState(pm));
  const { isAuthenticated, userName, instance } = useSecurity();
  const [drawer, setDrawer] = useState(false);
  const toggleSelect = () => {
    setDrawer(true);
  };

  const handleClickLogOut = async () => {
    gtag('event', 'logout');
    await instance.logoutRedirect();
  };

  const handleIncludeTaxesChange = (name, value) => {
    pm.set('includeTaxes', value);
    setSettings({
      ...settings,
      includeTaxes: value,
    });
    gtag('event', 'setting_change', {
      event_category: 'Include taxes',
      event_label: value ? 'yes' : 'no',
    });
  };
  const handleLLYChange = (name, value) => {
    pm.set('useLLY', value);
    setSettings({
      ...settings,
      useLLY: value,
    });
    gtag('event', 'setting_change', {
      event_category: 'use LLY',
      event_label: value === true ? 'yes' : 'no',
    });
  };

  const handleCurrencyAsUsdChange = (name, value) => {
    pm.set('useUsdAmount', value);
    setSettings({
      ...settings,
      useUsdAmount: value,
    });
    gtag('event', 'setting_change', {
      event_category: 'Reporting currency',
      event_label: value === true ? 'yes' : 'no',
    });
  };
  const handleSelectedLanguage = value => {
    pm.set('preferredLanguage', value);
    setLanguage(value);
    setSettings({
      ...settings,
      preferredLanguage: value,
    });
    gtag('event', 'setting_change', {
      event_category: 'Preferred Language',
      event_label: value,
    });
  };

  const handleKPIDefinitionClick = () => {
    history.push('/kpi-definition');
    setDrawer(false);
  };

  return (
    <>
      {children(settings, toggleSelect)}
      <Drawer
        anchor="right"
        open={drawer}
        onClose={_ => {
          onClose();
          setDrawer(false);
        }}
      >
        <MainMenu
          userName={userName}
          settings={settings}
          showLogOut={isAuthenticated}
          onClickLogOut={handleClickLogOut}
        >
          <MainMenuOption
            label={withTraslation('currencies_as_usd')}
            name="useUsdAmount"
            checked={settings.useUsdAmount}
            onChange={handleCurrencyAsUsdChange}
          />
          <MainMenuOption
            label={withTraslation('incluede_tax')}
            name="includeTaxes"
            checked={settings.includeTaxes}
            onChange={handleIncludeTaxesChange}
          />
          <MainMenuOption
            label={withTraslation('useLLY')}
            name="useLLY"
            checked={settings.useLLY}
            onChange={handleLLYChange}
          />
          <MainMenuOption
            label={withTraslation('kpi-definition')}
            switchComponent={false}
            onClickComponent={true}
            onChange={handleKPIDefinitionClick}
          />
          <MainMenuSelectOption
            selected={settings.preferredLanguage}
            onSelectChange={handleSelectedLanguage}
          />
        </MainMenu>
      </Drawer>
    </>
  );
};

UserShell.propTypes = {
  onClose: PropTypes.func,
};

export default withRouter(UserShell);
