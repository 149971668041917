import React from 'react';
import {
  withStyles,
  ListItem,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Text2 } from '../../typography';

const listItemStyles = theme => ({
  root: {
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '&$focusVisible': {
      background: 'transparent',
    },
  },
  button: {
    '&:hover': {
      background: 'transparent',
    },
  },
  focusVisible: {},
});

const StyledListItem = withStyles(listItemStyles)(ListItem);

const styles = theme => ({
  switch: {
    marginLeft: theme.spacing(1.5),
    marginRight: 0,
  },
  onClick: {
    lineHeight: '30px',
    cursor: 'pointer',
    margin: `${theme.spacing(0.5)}px 0`,
  },
  dropdown: {
    color: '#fff',
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid #fff',
    },
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
});

const MainMenuOption = ({
  classes,
  label,
  name,
  checked,
  onChange,
  switchComponent = true,
  onClickComponent = false,
  items = [],
  dropdownComponent = false,
}) => {
  const handleClick = e => {
    e.preventDefault();
    onChange(name, !checked);
  };

  return (
    <StyledListItem
      button
      tabIndex={-1}
      disableGutters
      disableRipple
      onClick={handleClick}
    >
      {onClickComponent ? (
        <Text2 component="span" onClick={onChange} className={classes.onClick}>
          {label}
        </Text2>
      ) : (
        <>
          <Text2 component="span">{label}</Text2>
          {switchComponent && (
            <Switch
              color="default"
              className={classes.switch}
              checked={checked}
            />
          )}
          {dropdownComponent && (
            <Select
              value={checked}
              onChange={event => {
                onChange(name, event.target.value);
              }}
              className={classes.dropdown}
            >
              {items.map(item => (
                <MenuItem key={`language-${item.id}`} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
    </StyledListItem>
  );
};

const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
});

MainMenuOption.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  items: PropTypes.arrayOf(itemShape),
  switchComponent: PropTypes.bool,
  dropdownComponent: PropTypes.bool,
  onClickComponent: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withStyles(styles, { name: 'LriMainMenuOption' })(
  MainMenuOption
);
