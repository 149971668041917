import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CardGrid, PromotedMetricCard, AddKPICard } from '../components/Cards';
import Header from '../components/Header';
import KPIList from '../components/KPIList';
import { Panel } from '../components/Panel';
import LoadingBar from '../components/LoadingBar';
import SectionSpacer from '../components/SectionSpacer';
import TimeStampPanel from '../components/TimeStampPanel';
import MetricsShell from '../data/components/MetricsShell';
import SortShell from '../data/components/SortShell';
import useMetricsData from '../data/hooks/useMetricsData';
import { transformMetricsInPanels } from '../data/utils/transforms';
import { LocationAndParamsShape } from '../location';
import { PageView, GTAG_EVENT } from '../gtag';
import { ErrorPage } from '../components/Errors';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  fontSize: {
    fontSize: '8px',
  },
}));

const FavMetricCard = ({ onClick, metric, useLLY }) => {
  const classes = useStyles();
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };
  if (!metric) {
    return <AddKPICard onClick={handleClick} />;
  }
  return (
    <div className={classes.fullWidth}>
      <PromotedMetricCard
        // onClick={handleClick}
        key={metric.id}
        metric={metric}
        useLLY={useLLY}
        moreDetails={metric.hasTrends}
      />
    </div>
  );
};

const SentimentCards = ({
  data,
  favMetric,
  onFavMetricClick,
  useLLY,
  useUsdAmount,
}) => {
  const classes = useStyles();
  return (
    data && (
      <>
        <CardGrid justify="flex-start" className={classes.fontSize}>
          {data.map(m => (
            <PromotedMetricCard
              key={m.id}
              metric={m}
              useLLY={useLLY}
              moreDetails={m.hasTrends}
              useUsdAmount={useUsdAmount}
            />
          ))}
          <FavMetricCard
            metric={favMetric}
            onClick={onFavMetricClick}
            useLLY={useLLY}
          />
        </CardGrid>
      </>
    )
  );
};

const KPIsCore = ({
  promotedKpis,
  favMetric,
  onSelectSort,
  onSelectFav,
  otherKpis,
  revenueKPIs,
  sfsRevenue,
  bopisRevenue,
  useLLY,
  intl,
  useUsdAmount,
}) => {
  const transformedOtherKPIs = transformMetricsInPanels(otherKpis);
  return (
    <>
      <Header
        title={intl.formatMessage({ id: 'highlight_kpis' })}
        icon
        onIconClick={onSelectFav}
      />
      <SentimentCards
        data={promotedKpis}
        favMetric={favMetric}
        onFavMetricClick={onSelectFav}
        useLLY={useLLY}
        useUsdAmount={useUsdAmount}
      />
      <SectionSpacer />
      <Header title={intl.formatMessage({ id: 'revenue' })} />
      <Panel title={intl.formatMessage({ id: 'layer_1' })}>
        <KPIList
          metrics={revenueKPIs}
          revenueStream={'IN_STORE_SALES'}
          useLLY={useLLY}
          useUsdAmount={useUsdAmount}
        />
      </Panel>
      <Panel
        title={intl.formatMessage({ id: 'layer_2' })}
        collapsible
        customExpandIcons={[ExpandMoreIcon, ExpandLessIcon]}
      >
        <KPIList
          metrics={sfsRevenue}
          revenueStream={'SFS_SALES'}
          useLLY={useLLY}
          useUsdAmount={useUsdAmount}
        />

        <KPIList
          metrics={bopisRevenue}
          revenueStream={'BOPIS_SALES'}
          useLLY={useLLY}
          useUsdAmount={useUsdAmount}
        />
      </Panel>
      <SectionSpacer />
      <Header
        title={intl.formatMessage({ id: 'other_kpis' })}
        icon
        onIconClick={onSelectSort}
      />
      {transformedOtherKPIs.map((panel, index) => (
        <Panel
          title={intl.formatMessage({ id: panel.panelName })}
          key={panel.panelName}
          collapsible
          initialCollapsed={index !== 0}
          customExpandIcons={[ExpandMoreIcon, ExpandLessIcon]}
          showNoGap
        >
          <KPIList
            metrics={panel.sections}
            key={`${panel.panelName}-section`}
            useLLY={useLLY}
            useUsdAmount={useUsdAmount}
          />
        </Panel>
      ))}
    </>
  );
};

const KPIsDataContainer = ({
  period,
  location: locationAndParams,
  userSettings,
  showTimeStamp,
  intl,
}) => {
  const { useUsdAmount, includeTaxes, useLLY } = userSettings;
  const { location, params: locationParams } = locationAndParams;
  const salesBucketValue = 'IN_STORE_SALES';
  const sfsSalesValue = 'SFS_SALES';
  const bopisSalesValue = 'BOPIS_SALES';
  const locationId = location.id;

  const {
    responseData = [],
    loading,
    error,
    refetch,
  } = useMetricsData(
    locationId,
    locationParams,
    period.id,
    useUsdAmount,
    includeTaxes,
    useLLY,
    period.periods_trends,
    period.selectedStartDate,
    period.selectedEndDate
  );

  const data = responseData[salesBucketValue] || [];
  const sfsData = responseData[sfsSalesValue] || [];
  const dataBOPIS = responseData[bopisSalesValue] || [];

  // Filter for revenue KPIs
  const listOfKPIs = ['retail_sales_net_value', 'aos_sales', 'aos_percentage'];
  const revenueKPIs = data
    .filter(metric => {
      return (
        metric.id &&
        listOfKPIs.some(excludeKPIs => metric.id.includes(excludeKPIs))
      );
    })
    .sort((a, b) => {
      if (a.id.includes('aos') && !b.id.includes('aos')) {
        return -1;
      }
      if (!a.id.includes('aos') && b.id.includes('aos')) {
        return 1;
      }
      return 0;
    });
  const sfsRevenue = sfsData.filter(metric => {
    return metric.id && metric.id.includes('retail_sales_net_value');
  });

  const bopisRevenue = dataBOPIS?.filter(metric => {
    return metric.id && metric.id.includes('retail_sales_net_value');
  });

  if (loading) {
    return <LoadingBar />;
  }

  if (error) {
    return (
      <ErrorPage
        message={intl.formatMessage({ id: 'not_load_kpis' })}
        onClickRetry={refetch}
      />
    );
  }

  return (
    <>
      {data.formattedTimeStamp && showTimeStamp && (
        <TimeStampPanel formattedValue={data.formattedTimeStamp} />
      )}
      <SortShell
        onSort={({ id }) => {
          // gtag('event', 'sort', { event_category: 'KPIs', event_label: id });
          GTAG_EVENT({ view: 'sort', category: 'KPIs', label: id });
        }}
      >
        {(sort, toggleSortDrawer) => {
          const promotedKpis = data.filter(x => x.promoted);
          const otherKpis = data
            .filter(
              x =>
                !listOfKPIs.some(excludeKPIs => x.id.includes(excludeKPIs))
            )
            .sort(sort.func);
          return (
            <MetricsShell metrics={otherKpis}>
              {(favMetric, toggleFavSelect) => (
                <PageView
                  params={{
                    period_id: period.id,
                    location_id: location.name,
                    channel: locationParams.channel,
                  }}
                >
                  <KPIsCore
                    promotedKpis={promotedKpis}
                    revenueKPIs={revenueKPIs}
                    sfsRevenue={sfsRevenue}
                    bopisRevenue={bopisRevenue}
                    favMetric={favMetric}
                    otherKpis={otherKpis}
                    onSelectSort={toggleSortDrawer}
                    onSelectFav={toggleFavSelect}
                    useLLY={useLLY}
                    loading={loading}
                    intl={intl}
                    useUsdAmount={useUsdAmount}
                  />
                </PageView>
              )}
            </MetricsShell>
          );
        }}
      </SortShell>
    </>
  );
};

KPIsDataContainer.propTypes = {
  period: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  location: LocationAndParamsShape.isRequired,
  userSettings: PropTypes.shape({
    useUsdAmount: PropTypes.bool.isRequired,
    includeTaxes: PropTypes.bool.isRequired,
  }),
};

const KPIs = React.memo(
  injectIntl(KPIsDataContainer),
  (prevProps, nextProps) =>
    prevProps.location === nextProps.location &&
    prevProps.period === nextProps.period &&
    prevProps.userSettings === nextProps.userSettings
);

export default KPIs;
