import { subYears } from 'date-fns';
import { useMemo } from 'react';

import { useData, getPeriods } from '../../api';
import { usePreferencesManager } from '../user-preferences';

/**
 * @param {string} value
 */
function parseDate(value) {
  // Parse date as UTC.
  // TODO: Handle timezones correctly.
  // if (/\d$/.test(value)) {
  //   value += 'Z';
  // }
  // return parseISO(value);
  // return new Date(value).toUTCString();
  return new Date(value);
}

export default function usePeriodData() {
  const { data, error, loading } = useData(getPeriods, {}, 'periods');
  const pm = usePreferencesManager();
  const refreshDate = JSON.parse(pm.get('data_refresh_date') || '{}');
  refreshDate.current_date = data?.current_date;
  refreshDate.period_updated = new Date().getTime();
  pm.set('data_refresh_date', JSON.stringify(refreshDate));

  const transformedData = useMemo(() => {
    if (!data) {
      return;
    }

    const minDate = subYears(new Date(), 1);
    const periods = {};
    // key = day, week, quarter, etc.
    for (const key of Object.keys(data)) {
      if (Array.isArray(data[key])) {
        periods[key] = [];
        for (const periodData of data[key]) {
          const startDate = parseDate(periodData.start);
          // Trim the fat periods response a bit. Could be trimmed more finely but
          // this will do for now.
          if (startDate < minDate) {
            // continue;
          }
          const endDate = parseDate(periodData.end);
          periods[key].push({
            id: periodData.id,
            pyId: periodData.previous_period,
            currentDate: data.current_date,
            refreshDate: data.current_date,
            periods_trends: data.periods_trends,
            startDate,
            endDate,
          });
        }
      }
    }

    for (const periodList of Object.values(periods)) {
      periodList.sort((a, b) => a.startDate - b.startDate);
    }

    return periods;
  }, [data]);

  return { data: transformedData, loading, error };
}
